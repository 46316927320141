import React from 'react'
import intl from 'react-intl-universal'
import Tippy from '@tippyjs/react'
import classNames from 'classnames'
import { useQuery } from '@apollo/client'
import { logout } from 'actions/auth_actions'
import { trackButton, trackButtonEnhanced } from 'utils/track'
import { BASE_DASHBOARD_URL } from 'constants/General'
import Lock from 'components/icons/Lock'
import { showModal } from 'components/utils/CustomModal'
import { UpsellModal } from 'components/modals/UpsellModal'
import { AppState, getAppStateQuery } from 'graphql/queries/app/getAppState'
import { Button } from 'components/utils/Button'
import { GetOrganizationsRes, GetOrganizationsVars, getOrganizationsQuery } from 'graphql/queries/organization/getOrganziations'
import { useAppState } from 'graphql/hooks/useAppState'
import { GetCurrentSubscriptionRes, GetCurrentSubscriptionVars, getCurrentSubscriptionQuery } from 'graphql/queries/user/getUser'
import { capitalize } from 'utils/functions'
import { useActiveWorkspaceId } from 'hooks/useActiveWorkspaceId'
import { useNavigate } from 'react-router-dom'

type SidebarMenuProps = {
  tab: string;
  menuExpanded: boolean;
  mobile?: boolean;
  extraItems: string[];
  lockedItems: string[];
  setTab: (id: string) => void;
  closeMenu?: () => void;
}

export const SidebarMenu = ({ tab, menuExpanded, mobile, extraItems, lockedItems, setTab, closeMenu }: SidebarMenuProps) => {
  const navigate = useNavigate()
  const appState = useAppState()
  const activeWorkspaceId = useActiveWorkspaceId()

  const { data: subData } = useQuery<GetCurrentSubscriptionRes, GetCurrentSubscriptionVars>(getCurrentSubscriptionQuery, {
    skip: !appState,
    variables: { userId: appState!.loggedInAs.uid },
  })

  const { data: orgData } = useQuery<GetOrganizationsRes, GetOrganizationsVars>(getOrganizationsQuery, {
    skip: !appState,
    variables: {
      searchText: '',
      skip: 0,
      limit: 100,
      limitToUserOrganizations: appState?.loggedInAs.uid || '',
      limitToManagerOrganizations: appState?.loggedInAs.uid || '',
      disabled: false,
    },
    errorPolicy: 'all',
  })

  const isWorkspaceManager = subData?.user.managerOrganizations.includes(activeWorkspaceId)

  return (
    <nav className="mt-5 bg-white space-y-1 md:space-y-2 text-center">
      {!mobile && (
        <>
          <SidebarMenuItem
            id="home"
            text="home_tab_home"
            icon="icon-home-light"
            label="Home"
            screen="home"
            selected={tab.startsWith('home')}
            menuExpanded={menuExpanded}
            mobile={false}
            onClick={setTab}
          />

          <SidebarMenuItem
            id="programs"
            text="home_tab_programs"
            icon="icon-programs-outline"
            label="My Programs"
            screen="home"
            selected={tab.startsWith('programs')}
            menuExpanded={menuExpanded}
            mobile={false}
            onClick={setTab}
          />

          <SidebarMenuItem
            id="groups"
            text="home_tab_groups"
            icon="icon-users-light"
            label="My Groups"
            screen="home"
            selected={tab.startsWith('groups')}
            menuExpanded={menuExpanded}
            mobile={false}
            onClick={setTab}
          />

          {!!extraItems?.includes('dashboard') && (
            <SidebarMenuItem
              id="dashboard"
              text="global_dashboard"
              icon="icon-dashboard"
              label={intl.get('global_dashboard')}
              screen="home"
              selected={false}
              locked={!!lockedItems?.includes('dashboard')}
              menuExpanded={menuExpanded}
              mobile={false}
              onClick={() => {
                if (!!lockedItems?.includes('dashboard')) {
                  const modal = showModal({
                    title: intl.get('home_group_invite'),
                    component: <UpsellModal type="free" track="generic_starter" close={() => modal.close()} />,
                    onlyContent: true,
                    className: 'add-action-modal',
                  })
                  return
                }

                if ((orgData?.res.organizationsCount || 0) <= 1) {
                  return window.open(
                    `${BASE_DASHBOARD_URL}/organization/${orgData?.res.organizations[0].id}`,
                    '_blank'
                  )
                }

                if (isWorkspaceManager) {
                  return window.open(
                    `${BASE_DASHBOARD_URL}/organization/${activeWorkspaceId}`,
                    '_blank'
                  )
                }

                return window.open(
                  BASE_DASHBOARD_URL,
                  '_blank'
                )
              }}
            />
          )}

          {!!extraItems?.includes('explore') && (
            <SidebarMenuItem
              id="explore"
              text="gnowbelearn_explore"
              icon="icon-book-light"
              label="Explore"
              screen="home"
              selected={tab.startsWith('explore')}
              menuExpanded={menuExpanded}
              mobile={false}
              onClick={setTab}
            />
          )}
        </>
      )}

      {mobile && (
        <>
          <SidebarMenuItem
            id="profile"
            text="home_my_profile"
            icon="icon-user-circle-light"
            label="My Profile"
            screen="home"
            selected={tab.startsWith('profile')}
            menuExpanded={menuExpanded}
            mobile={true}
            onClick={() => {
              closeMenu && closeMenu()
              navigate('/profile')
            }}
          />

          <SidebarMenuItem
            id="settings"
            text="settings_text"
            icon="icon-cog-light"
            label="Settings"
            screen="home"
            selected={tab.startsWith('settings')}
            menuExpanded={menuExpanded}
            mobile={true}
            onClick={() => {
              closeMenu && closeMenu()
              navigate('/settings')
            }}
          />

          <SidebarMenuItem
            id="archive"
            text="archived_programs"
            icon="icon-archive-light"
            label="Archived programs"
            screen="home"
            selected={tab.startsWith('archive')}
            menuExpanded={menuExpanded}
            mobile={true}
            onClick={setTab}
          />

          {!!extraItems?.includes('dashboard') && (
            <SidebarMenuItem
              id="dashboard"
              text="global_dashboard"
              icon="icon-dashboard"
              label={intl.get('global_dashboard')}
              screen="dashboard"
              selected={false}
              locked={!!lockedItems?.includes('dashboard')}
              menuExpanded={menuExpanded}
              mobile={false}
              onClick={() => {
                if (!!lockedItems?.includes('dashboard')) {
                  const modal = showModal({
                    title: intl.get('home_group_invite'),
                    component: <UpsellModal type="free" track="generic_starter" close={() => modal.close()} />,
                    onlyContent: true,
                    className: 'add-action-modal',
                  })
                  return
                }
                return window.location.href = BASE_DASHBOARD_URL
              }}
            />
          )}

          {!!extraItems?.includes('explore') && (
            <SidebarMenuItem
              id="explore"
              text="gnowbelearn_explore"
              icon="icon-book-light"
              label="Explore"
              screen="home"
              selected={tab.startsWith('explore')}
              menuExpanded={menuExpanded}
              mobile={false}
              onClick={setTab}
            />
          )}

          <SidebarMenuItem
            id="help"
            text="home_help_center"
            icon="icon-question-circle-light"
            label="Gnowbe Help Center"
            screen="home"
            selected={false}
            menuExpanded={menuExpanded}
            mobile={true}
            onClick={() => {
              trackButton({
                button: 'help_center',
                on_screen: 'home',
              })

              trackButtonEnhanced({
                button: 'Zendesk',
                onScreen: 'Home'
              })

              window.open('https://gnowbe.zendesk.com/hc/en-us')
            }}
          />

          <SidebarMenuItem
            id="sign-out"
            text="sign_out"
            icon="icon-sign-out-light"
            label="Log out"
            screen="home"
            selected={false}
            menuExpanded={menuExpanded}
            mobile={true}
            onClick={() => logout({email: subData?.user.email})}
          />
        </>
      )}
    </nav>
  )
}

type SidebarMenuItemProps = {
  id: string;
  text: string;
  icon: string;
  label: string;
  showTip?: boolean;
  selected: boolean;
  menuExpanded: boolean;
  mobile: boolean;
  screen: string;
  locked?: boolean;
  onClick: (id: string) => void;
}

export const SidebarMenuItem = ({ id, text, icon, label, showTip, selected, menuExpanded, mobile, screen, locked, onClick }: SidebarMenuItemProps) => {
  const { data: appData } = useQuery<AppState>(getAppStateQuery)

  const click = () => {
    trackButton({
      button: id,
      on_screen: screen,
    })

    trackButtonEnhanced({
      button: label,
      onScreen: `${capitalize(screen)} (${menuExpanded ? 'expanded' : 'collapsed'})`,
    })

    onClick(id)
  }

  const dashboardClick = (e) => {
    e.stopPropagation()
    e.preventDefault()
    const modal = showModal({
      onlyContent: true,
      className: 'add-action-modal',
      component: (
        <div className="pt-4 pb-2">
          <img className="mx-auto" src="/images/dashboard-upsell.svg" />
          <div className="my-4 font-noto-sans text-2xl text-center font-bold">A quick tour of Gnowbe's dashboard</div>
          <p>You now have access to Gnowbe's dashboard. Take a tour of the features to understand how to utilize our features to your best advantage. You will be navigated to the Gnowbe Dashboard to begin.</p>
          <div className="space-y-4 text-center">
            <div>
              <Button type="primary" text="Start the tour" onClick={() => {
                window.location.href = `${BASE_DASHBOARD_URL}/d/organization/${appData?.appState.currentCompanyIdCourseId.split('-')[0]}/showMainGuide`
              }} />
            </div>
            <div><Button type="text" text="Skip for now" onClick={() => modal.close()} /></div>
          </div>
        </div>
      ),
    })
  }

  const btnCn = classNames({
    selected,
    'sidebar-item group': true,
  })

  const textCn = classNames({
    'border-actions-multiple_choice': selected,
    'border-transparent': !selected,
    'text-coral': id === 'sign-out',
    'group-hover:text-actions-multiple_choice': true,
  })

  const iconCn = classNames({
    'icon group-hover:text-actions-multiple_choice': true,
    [icon]: true,
    'text-coral': id === 'sign-out',
    'border-b-2 border-transparent': true,
  })

  if (showTip === false) {
    return (
      <button className={btnCn} onClick={click} aria-label={label}>
        <i className={iconCn} />

        {(menuExpanded || mobile) && (
          <span className={textCn}>
            {intl.get(text).d(text)}
          </span>
        )}
      </button>
    )
  }

  let itemIcon = <i className={iconCn} />

  if (id === 'dashboard') itemIcon = <svg aria-hidden="true" focusable="false" className={`${iconCn} w-6 h-6 ${menuExpanded ? 'mt-[0.5px]' : 'mt-[1px]'}`} role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M512 0H64C28.65 0 0 28.65 0 64v288c0 35.35 28.65 64 64 64h148.3l-9.6 48H152C138.8 464 128 474.8 128 488S138.8 512 152 512h272c13.25 0 24-10.75 24-24s-10.75-24-24-24h-50.73L363.7 416H512c35.35 0 64-28.65 64-64V64C576 28.65 547.3 0 512 0zM324.3 464H251.7L261.3 416h53.46L324.3 464zM528 352c0 8.822-7.178 16-16 16H64c-8.822 0-16-7.178-16-16V304h480V352zM528 256h-480V64c0-8.822 7.178-16 16-16h448c8.822 0 16 7.178 16 16V256z"></path></svg>
  if (id === 'explore') itemIcon = <svg aria-hidden="true" focusable="false" className={`${iconCn} w-6 h-6 ${menuExpanded ? 'mt-[0.5px]' : 'mt-[2px]'}`} role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M358.1 143.5L243.6 195.8c-5.957 2.723-13.06 9.824-15.78 15.78l-52.33 114.5c-12.32 26.95 15.45 54.72 42.4 42.41l114.5-52.32c5.939-2.715 13.03-9.817 15.75-15.76l52.34-114.5C412.8 158.1 385 131.2 358.1 143.5zM310.6 278.6c-12.5 12.5-32.75 12.5-45.25 0c-12.5-12.5-12.5-32.75 0-45.25c12.5-12.5 32.75-12.5 45.25 0C323.1 245.9 323.1 266.1 310.6 278.6zM288 0C146.6 0 32 114.6 32 256s114.6 256 256 256s256-114.6 256-256S429.4 0 288 0zM288 464c-114.7 0-208-93.31-208-208S173.3 48 288 48s208 93.31 208 208S402.7 464 288 464z"></path></svg>

  return (
    <Tippy placement="right" content={intl.get(text)} disabled={menuExpanded}>
      <button className={btnCn} onClick={click} aria-label={label}>
        {itemIcon}

        {(menuExpanded || mobile) && (
          <span id={`sidebar-${id}`} className={textCn}>
            {intl.get(text).d(text)}
          </span>
        )}

        {(menuExpanded || mobile) && label === 'Explore' && (
          <span className="text-coral uppercase border-none text-xs">beta</span>
        )}

        {locked && (
          <div className="flex-1 text-right">
            <Lock className={`${iconCn} w-4 h-4 mt-[0.5px]`} />
          </div>
        )}
      </button>
    </Tippy>
  )
}
